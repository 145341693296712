import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './ascension.scss';
import { Table } from 'react-bootstrap';

const EsGuidesLevelPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ascension-guide'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Level requirements</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_level.png"
            alt="Level requirements"
          />
        </div>
        <div className="page-details">
          <h1>Level requirements</h1>
          <h2>Material requirements for levels in Eversoul.</h2>
          <p>
            Last updated: <strong>09/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Level requirements" />
        <p>
          The table below shows how much materials do you need to upgrade the
          levels of your characters. While from levels 1 to 201, you will need
          to use Mana Crystals every 20 levels, starting from level 202 you will
          need to use them every level.
        </p>
        <h5>Mana Crystal needed to reach level:</h5>
        <ul>
          <li>Level 11 - 10 Mana Crystal</li>
          <li>Level 21 - 50 Mana Crystal</li>
          <li>Level 41 - 100 Mana Crystal</li>
          <li>Level 61 - 250 Mana Crystal</li>
          <li>Level 81 - 500 Mana Crystal</li>
          <li>Level 101 - 1 200 Mana Crystal</li>
          <li>Level 121 - 3 000 Mana Crystal</li>
          <li>Level 141 - 6 000 Mana Crystal</li>
          <li>Level 161 - 12 000 Mana Crystal</li>
          <li>Level 181 - 24 000 Mana Crystal</li>
          <li>Level 201 - 30 000 Mana Crystal</li>
        </ul>
        <p>
          Also, it seems that compared to most idle games, in Eversoul the Sync
          Device doesn't transform, allowing you to level it instead of the
          characters, but you have to continue to level each character
          separately.
        </p>
        <SectionHeader title="Data" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Level</th>
              <th>Gold</th>
              <th>Mana Dust</th>
              <th>Mana Crystal</th>
              <th>Gold (cumulative)</th>
              <th>Mana Dust (cumulative)</th>
              <th>Mana Crystal (cumulative)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
            </tr>
            <tr>
              <td>2</td>
              <td>30</td>
              <td>90</td>
              <td>0</td>
              <td>30</td>
              <td>90</td>
              <td>0</td>
            </tr>
            <tr>
              <td>3</td>
              <td>80</td>
              <td>150</td>
              <td>0</td>
              <td>110</td>
              <td>240</td>
              <td>0</td>
            </tr>
            <tr>
              <td>4</td>
              <td>140</td>
              <td>220</td>
              <td>0</td>
              <td>250</td>
              <td>460</td>
              <td>0</td>
            </tr>
            <tr>
              <td>5</td>
              <td>210</td>
              <td>300</td>
              <td>0</td>
              <td>460</td>
              <td>760</td>
              <td>0</td>
            </tr>
            <tr>
              <td>6</td>
              <td>290</td>
              <td>390</td>
              <td>0</td>
              <td>750</td>
              <td>1150</td>
              <td>0</td>
            </tr>
            <tr>
              <td>7</td>
              <td>380</td>
              <td>490</td>
              <td>0</td>
              <td>1130</td>
              <td>1640</td>
              <td>0</td>
            </tr>
            <tr>
              <td>8</td>
              <td>480</td>
              <td>600</td>
              <td>0</td>
              <td>1610</td>
              <td>2240</td>
              <td>0</td>
            </tr>
            <tr>
              <td>9</td>
              <td>590</td>
              <td>720</td>
              <td>0</td>
              <td>2200</td>
              <td>2960</td>
              <td>0</td>
            </tr>
            <tr>
              <td>10</td>
              <td>710</td>
              <td>850</td>
              <td>0</td>
              <td>2910</td>
              <td>3810</td>
              <td>0</td>
            </tr>
            <tr>
              <td>11</td>
              <td>840</td>
              <td>990</td>
              <td>10</td>
              <td>3750</td>
              <td>4800</td>
              <td>10</td>
            </tr>
            <tr>
              <td>21</td>
              <td>2570</td>
              <td>2940</td>
              <td>50</td>
              <td>20990</td>
              <td>24600</td>
              <td>60</td>
            </tr>
            <tr>
              <td>41</td>
              <td>7500</td>
              <td>9450</td>
              <td>100</td>
              <td>123560</td>
              <td>148290</td>
              <td>160</td>
            </tr>
            <tr>
              <td>61</td>
              <td>15000</td>
              <td>18000</td>
              <td>250</td>
              <td>339810</td>
              <td>420390</td>
              <td>410</td>
            </tr>
            <tr>
              <td>81</td>
              <td>32000</td>
              <td>39000</td>
              <td>500</td>
              <td>797310</td>
              <td>991390</td>
              <td>910</td>
            </tr>
            <tr>
              <td>101</td>
              <td>58000</td>
              <td>80000</td>
              <td>1200</td>
              <td>1668310</td>
              <td>2192390</td>
              <td>2110</td>
            </tr>
            <tr>
              <td>121</td>
              <td>129000</td>
              <td>184000</td>
              <td>3000</td>
              <td>3411310</td>
              <td>4626390</td>
              <td>5110</td>
            </tr>
            <tr>
              <td>141</td>
              <td>269000</td>
              <td>461000</td>
              <td>6000</td>
              <td>7461310</td>
              <td>11187390</td>
              <td>11110</td>
            </tr>
            <tr>
              <td>161</td>
              <td>388000</td>
              <td>725000</td>
              <td>12000</td>
              <td>14211310</td>
              <td>23239390</td>
              <td>23110</td>
            </tr>
            <tr>
              <td>181</td>
              <td>449000</td>
              <td>970000</td>
              <td>24000</td>
              <td>22655310</td>
              <td>40266390</td>
              <td>47110</td>
            </tr>
            <tr>
              <td>201</td>
              <td>491000</td>
              <td>1378000</td>
              <td>30000</td>
              <td>32058310</td>
              <td>63636390</td>
              <td>77110</td>
            </tr>
            <tr>
              <td>202</td>
              <td>500000</td>
              <td>1400000</td>
              <td>800</td>
              <td>32558310</td>
              <td>65036390</td>
              <td>77910</td>
            </tr>
            <tr>
              <td>203</td>
              <td>525000</td>
              <td>1470000</td>
              <td>840</td>
              <td>33083310</td>
              <td>66506390</td>
              <td>78750</td>
            </tr>
            <tr>
              <td>204</td>
              <td>550000</td>
              <td>1540000</td>
              <td>880</td>
              <td>33633310</td>
              <td>68046390</td>
              <td>79630</td>
            </tr>
            <tr>
              <td>205</td>
              <td>575000</td>
              <td>1610000</td>
              <td>920</td>
              <td>34208310</td>
              <td>69656390</td>
              <td>80550</td>
            </tr>
            <tr>
              <td>206</td>
              <td>600000</td>
              <td>1680000</td>
              <td>960</td>
              <td>34808310</td>
              <td>71336390</td>
              <td>81510</td>
            </tr>
            <tr>
              <td>207</td>
              <td>625000</td>
              <td>1750000</td>
              <td>1000</td>
              <td>35433310</td>
              <td>73086390</td>
              <td>82510</td>
            </tr>
            <tr>
              <td>208</td>
              <td>650000</td>
              <td>1820000</td>
              <td>1040</td>
              <td>36083310</td>
              <td>74906390</td>
              <td>83550</td>
            </tr>
            <tr>
              <td>209</td>
              <td>675000</td>
              <td>1890000</td>
              <td>1080</td>
              <td>36758310</td>
              <td>76796390</td>
              <td>84630</td>
            </tr>
            <tr>
              <td>210</td>
              <td>700000</td>
              <td>1960000</td>
              <td>1120</td>
              <td>37458310</td>
              <td>78756390</td>
              <td>85750</td>
            </tr>
            <tr>
              <td>211</td>
              <td>725000</td>
              <td>2030000</td>
              <td>1160</td>
              <td>38183310</td>
              <td>80786390</td>
              <td>86910</td>
            </tr>
            <tr>
              <td>212</td>
              <td>750000</td>
              <td>2100000</td>
              <td>1200</td>
              <td>38933310</td>
              <td>82886390</td>
              <td>88110</td>
            </tr>
            <tr>
              <td>213</td>
              <td>775000</td>
              <td>2170000</td>
              <td>1240</td>
              <td>39708310</td>
              <td>85056390</td>
              <td>89350</td>
            </tr>
            <tr>
              <td>214</td>
              <td>800000</td>
              <td>2240000</td>
              <td>1280</td>
              <td>40508310</td>
              <td>87296390</td>
              <td>90630</td>
            </tr>
            <tr>
              <td>215</td>
              <td>825000</td>
              <td>2310000</td>
              <td>1320</td>
              <td>41333310</td>
              <td>89606390</td>
              <td>91950</td>
            </tr>
            <tr>
              <td>216</td>
              <td>850000</td>
              <td>2380000</td>
              <td>1360</td>
              <td>42183310</td>
              <td>91986390</td>
              <td>93310</td>
            </tr>
            <tr>
              <td>217</td>
              <td>875000</td>
              <td>2450000</td>
              <td>1400</td>
              <td>43058310</td>
              <td>94436390</td>
              <td>94710</td>
            </tr>
            <tr>
              <td>218</td>
              <td>900000</td>
              <td>2520000</td>
              <td>1440</td>
              <td>43958310</td>
              <td>96956390</td>
              <td>96150</td>
            </tr>
            <tr>
              <td>219</td>
              <td>925000</td>
              <td>2590000</td>
              <td>1480</td>
              <td>44883310</td>
              <td>99546390</td>
              <td>97630</td>
            </tr>
            <tr>
              <td>220</td>
              <td>950000</td>
              <td>2660000</td>
              <td>1520</td>
              <td>45833310</td>
              <td>102206390</td>
              <td>99150</td>
            </tr>
            <tr>
              <td>221</td>
              <td>975000</td>
              <td>2730000</td>
              <td>1560</td>
              <td>46808310</td>
              <td>104936390</td>
              <td>100710</td>
            </tr>
            <tr>
              <td>222</td>
              <td>1000000</td>
              <td>2800000</td>
              <td>1600</td>
              <td>47808310</td>
              <td>107736390</td>
              <td>102310</td>
            </tr>
            <tr>
              <td>223</td>
              <td>1025000</td>
              <td>2870000</td>
              <td>1640</td>
              <td>48833310</td>
              <td>110606390</td>
              <td>103950</td>
            </tr>
            <tr>
              <td>224</td>
              <td>1050000</td>
              <td>2940000</td>
              <td>1680</td>
              <td>49883310</td>
              <td>113546390</td>
              <td>105630</td>
            </tr>
            <tr>
              <td>225</td>
              <td>1075000</td>
              <td>3010000</td>
              <td>1720</td>
              <td>50958310</td>
              <td>116556390</td>
              <td>107350</td>
            </tr>
            <tr>
              <td>226</td>
              <td>1100000</td>
              <td>3080000</td>
              <td>1760</td>
              <td>52058310</td>
              <td>119636390</td>
              <td>109110</td>
            </tr>
            <tr>
              <td>227</td>
              <td>1125000</td>
              <td>3150000</td>
              <td>1800</td>
              <td>53183310</td>
              <td>122786390</td>
              <td>110910</td>
            </tr>
            <tr>
              <td>228</td>
              <td>1150000</td>
              <td>3220000</td>
              <td>1840</td>
              <td>54333310</td>
              <td>126006390</td>
              <td>112750</td>
            </tr>
            <tr>
              <td>229</td>
              <td>1175000</td>
              <td>3290000</td>
              <td>1880</td>
              <td>55508310</td>
              <td>129296390</td>
              <td>114630</td>
            </tr>
            <tr>
              <td>230</td>
              <td>1200000</td>
              <td>3360000</td>
              <td>1920</td>
              <td>56708310</td>
              <td>132656390</td>
              <td>116550</td>
            </tr>
            <tr>
              <td>231</td>
              <td>1225000</td>
              <td>3430000</td>
              <td>1960</td>
              <td>57933310</td>
              <td>136086390</td>
              <td>118510</td>
            </tr>
            <tr>
              <td>232</td>
              <td>1250000</td>
              <td>3500000</td>
              <td>2000</td>
              <td>59183310</td>
              <td>139586390</td>
              <td>120510</td>
            </tr>
            <tr>
              <td>233</td>
              <td>1275000</td>
              <td>3570000</td>
              <td>2040</td>
              <td>60458310</td>
              <td>143156390</td>
              <td>122550</td>
            </tr>
            <tr>
              <td>234</td>
              <td>1300000</td>
              <td>3640000</td>
              <td>2080</td>
              <td>61758310</td>
              <td>146796390</td>
              <td>124630</td>
            </tr>
            <tr>
              <td>235</td>
              <td>1325000</td>
              <td>3710000</td>
              <td>2120</td>
              <td>63083310</td>
              <td>150506390</td>
              <td>126750</td>
            </tr>
            <tr>
              <td>236</td>
              <td>1350000</td>
              <td>3780000</td>
              <td>2160</td>
              <td>64433310</td>
              <td>154286390</td>
              <td>128910</td>
            </tr>
            <tr>
              <td>237</td>
              <td>1375000</td>
              <td>3850000</td>
              <td>2200</td>
              <td>65808310</td>
              <td>158136390</td>
              <td>131110</td>
            </tr>
            <tr>
              <td>238</td>
              <td>1400000</td>
              <td>3920000</td>
              <td>2240</td>
              <td>67208310</td>
              <td>162056390</td>
              <td>133350</td>
            </tr>
            <tr>
              <td>239</td>
              <td>1425000</td>
              <td>3990000</td>
              <td>2280</td>
              <td>68633310</td>
              <td>166046390</td>
              <td>135630</td>
            </tr>
            <tr>
              <td>240</td>
              <td>1450000</td>
              <td>4060000</td>
              <td>2320</td>
              <td>70083310</td>
              <td>170106390</td>
              <td>137950</td>
            </tr>
            <tr>
              <td>241</td>
              <td>1453125</td>
              <td>4068750</td>
              <td>2325</td>
              <td>71536435</td>
              <td>174175140</td>
              <td>140275</td>
            </tr>
            <tr>
              <td>242</td>
              <td>1456250</td>
              <td>4077500</td>
              <td>2330</td>
              <td>72992685</td>
              <td>178252640</td>
              <td>142605</td>
            </tr>
            <tr>
              <td>243</td>
              <td>1459375</td>
              <td>4086250</td>
              <td>2335</td>
              <td>74452060</td>
              <td>182338890</td>
              <td>144940</td>
            </tr>
            <tr>
              <td>244</td>
              <td>1462500</td>
              <td>4095000</td>
              <td>2340</td>
              <td>75914560</td>
              <td>186433890</td>
              <td>147280</td>
            </tr>
            <tr>
              <td>245</td>
              <td>1465625</td>
              <td>4103750</td>
              <td>2345</td>
              <td>77380185</td>
              <td>190537640</td>
              <td>149625</td>
            </tr>
            <tr>
              <td>246</td>
              <td>1468750</td>
              <td>4112500</td>
              <td>2350</td>
              <td>78848935</td>
              <td>194650140</td>
              <td>151975</td>
            </tr>
            <tr>
              <td>247</td>
              <td>1471875</td>
              <td>4121250</td>
              <td>2355</td>
              <td>80320810</td>
              <td>198771390</td>
              <td>154330</td>
            </tr>
            <tr>
              <td>248</td>
              <td>1475000</td>
              <td>4130000</td>
              <td>2360</td>
              <td>81795810</td>
              <td>202901390</td>
              <td>156690</td>
            </tr>
            <tr>
              <td>249</td>
              <td>1478125</td>
              <td>4138750</td>
              <td>2365</td>
              <td>83273935</td>
              <td>207040140</td>
              <td>159055</td>
            </tr>
            <tr>
              <td>250</td>
              <td>1481250</td>
              <td>4147500</td>
              <td>2370</td>
              <td>84755185</td>
              <td>211187640</td>
              <td>161425</td>
            </tr>
            <tr>
              <td>251</td>
              <td>1484375</td>
              <td>4156250</td>
              <td>2375</td>
              <td>86239560</td>
              <td>215343890</td>
              <td>163800</td>
            </tr>
            <tr>
              <td>252</td>
              <td>1487500</td>
              <td>4165000</td>
              <td>2380</td>
              <td>87727060</td>
              <td>219508890</td>
              <td>166180</td>
            </tr>
            <tr>
              <td>253</td>
              <td>1490625</td>
              <td>4173750</td>
              <td>2385</td>
              <td>89217685</td>
              <td>223682640</td>
              <td>168565</td>
            </tr>
            <tr>
              <td>254</td>
              <td>1493750</td>
              <td>4182500</td>
              <td>2390</td>
              <td>90711435</td>
              <td>227865140</td>
              <td>170955</td>
            </tr>
            <tr>
              <td>255</td>
              <td>1496875</td>
              <td>4191250</td>
              <td>2395</td>
              <td>92208310</td>
              <td>232056390</td>
              <td>173350</td>
            </tr>
            <tr>
              <td>256</td>
              <td>1500000</td>
              <td>4200000</td>
              <td>2400</td>
              <td>93708310</td>
              <td>236256390</td>
              <td>175750</td>
            </tr>
            <tr>
              <td>257</td>
              <td>1503125</td>
              <td>4208750</td>
              <td>2405</td>
              <td>95211435</td>
              <td>240465140</td>
              <td>178155</td>
            </tr>
            <tr>
              <td>258</td>
              <td>1506250</td>
              <td>4217500</td>
              <td>2410</td>
              <td>96717685</td>
              <td>244682640</td>
              <td>180565</td>
            </tr>
            <tr>
              <td>259</td>
              <td>1509375</td>
              <td>4226250</td>
              <td>2415</td>
              <td>98227060</td>
              <td>248908890</td>
              <td>182980</td>
            </tr>
            <tr>
              <td>260</td>
              <td>1512500</td>
              <td>4235000</td>
              <td>2420</td>
              <td>99739560</td>
              <td>253143890</td>
              <td>185400</td>
            </tr>
            <tr>
              <td>261</td>
              <td>1515625</td>
              <td>4243750</td>
              <td>2425</td>
              <td>101255185</td>
              <td>257387640</td>
              <td>187825</td>
            </tr>
            <tr>
              <td>262</td>
              <td>1518750</td>
              <td>4252500</td>
              <td>2430</td>
              <td>102773935</td>
              <td>261640140</td>
              <td>190255</td>
            </tr>
            <tr>
              <td>263</td>
              <td>1521875</td>
              <td>4261250</td>
              <td>2435</td>
              <td>104295810</td>
              <td>265901390</td>
              <td>192690</td>
            </tr>
            <tr>
              <td>264</td>
              <td>1525000</td>
              <td>4270000</td>
              <td>2440</td>
              <td>105820810</td>
              <td>270171390</td>
              <td>195130</td>
            </tr>
            <tr>
              <td>265</td>
              <td>1528125</td>
              <td>4278750</td>
              <td>2445</td>
              <td>107348935</td>
              <td>274450140</td>
              <td>197575</td>
            </tr>
            <tr>
              <td>266</td>
              <td>1531250</td>
              <td>4287500</td>
              <td>2450</td>
              <td>108880185</td>
              <td>278737640</td>
              <td>200025</td>
            </tr>
            <tr>
              <td>267</td>
              <td>1534375</td>
              <td>4296250</td>
              <td>2455</td>
              <td>110414560</td>
              <td>283033890</td>
              <td>202480</td>
            </tr>
            <tr>
              <td>268</td>
              <td>1537500</td>
              <td>4305000</td>
              <td>2460</td>
              <td>111952060</td>
              <td>287338890</td>
              <td>204940</td>
            </tr>
            <tr>
              <td>269</td>
              <td>1540625</td>
              <td>4313750</td>
              <td>2465</td>
              <td>113492685</td>
              <td>291652640</td>
              <td>207405</td>
            </tr>
            <tr>
              <td>270</td>
              <td>1543750</td>
              <td>4322500</td>
              <td>2470</td>
              <td>115036435</td>
              <td>295975140</td>
              <td>209875</td>
            </tr>
            <tr>
              <td>271</td>
              <td>1546875</td>
              <td>4331250</td>
              <td>2475</td>
              <td>116583310</td>
              <td>300306390</td>
              <td>212350</td>
            </tr>
            <tr>
              <td>272</td>
              <td>1550000</td>
              <td>4340000</td>
              <td>2480</td>
              <td>118133310</td>
              <td>304646390</td>
              <td>214830</td>
            </tr>
            <tr>
              <td>273</td>
              <td>1553125</td>
              <td>4348750</td>
              <td>2485</td>
              <td>119686435</td>
              <td>308995140</td>
              <td>217315</td>
            </tr>
            <tr>
              <td>274</td>
              <td>1556250</td>
              <td>4357500</td>
              <td>2490</td>
              <td>121242685</td>
              <td>313352640</td>
              <td>219805</td>
            </tr>
            <tr>
              <td>275</td>
              <td>1559375</td>
              <td>4366250</td>
              <td>2495</td>
              <td>122802060</td>
              <td>317718890</td>
              <td>222300</td>
            </tr>
            <tr>
              <td>276</td>
              <td>1562500</td>
              <td>4375000</td>
              <td>2500</td>
              <td>124364560</td>
              <td>322093890</td>
              <td>224800</td>
            </tr>
            <tr>
              <td>277</td>
              <td>1565625</td>
              <td>4383750</td>
              <td>2505</td>
              <td>125930185</td>
              <td>326477640</td>
              <td>227305</td>
            </tr>
            <tr>
              <td>278</td>
              <td>1568750</td>
              <td>4392500</td>
              <td>2510</td>
              <td>127498935</td>
              <td>330870140</td>
              <td>229815</td>
            </tr>
            <tr>
              <td>279</td>
              <td>1571875</td>
              <td>4401250</td>
              <td>2515</td>
              <td>129070810</td>
              <td>335271390</td>
              <td>232330</td>
            </tr>
            <tr>
              <td>280</td>
              <td>1575000</td>
              <td>4410000</td>
              <td>2520</td>
              <td>130645810</td>
              <td>339681390</td>
              <td>234850</td>
            </tr>
            <tr>
              <td>281</td>
              <td>1578125</td>
              <td>4418750</td>
              <td>2525</td>
              <td>132223935</td>
              <td>344100140</td>
              <td>237375</td>
            </tr>
            <tr>
              <td>282</td>
              <td>1581250</td>
              <td>4427500</td>
              <td>2530</td>
              <td>133805185</td>
              <td>348527640</td>
              <td>239905</td>
            </tr>
            <tr>
              <td>283</td>
              <td>1584375</td>
              <td>4436250</td>
              <td>2535</td>
              <td>135389560</td>
              <td>352963890</td>
              <td>242440</td>
            </tr>
            <tr>
              <td>284</td>
              <td>1587500</td>
              <td>4445000</td>
              <td>2540</td>
              <td>136977060</td>
              <td>357408890</td>
              <td>244980</td>
            </tr>
            <tr>
              <td>285</td>
              <td>1590625</td>
              <td>4453750</td>
              <td>2545</td>
              <td>138567685</td>
              <td>361862640</td>
              <td>247525</td>
            </tr>
            <tr>
              <td>286</td>
              <td>1593750</td>
              <td>4462500</td>
              <td>2550</td>
              <td>140161435</td>
              <td>366325140</td>
              <td>250075</td>
            </tr>
            <tr>
              <td>287</td>
              <td>1596875</td>
              <td>4471250</td>
              <td>2555</td>
              <td>141758310</td>
              <td>370796390</td>
              <td>252630</td>
            </tr>
            <tr>
              <td>288</td>
              <td>1600000</td>
              <td>4480000</td>
              <td>2560</td>
              <td>143358310</td>
              <td>375276390</td>
              <td>255190</td>
            </tr>
            <tr>
              <td>289</td>
              <td>1603125</td>
              <td>4488750</td>
              <td>2565</td>
              <td>144961435</td>
              <td>379765140</td>
              <td>257755</td>
            </tr>
            <tr>
              <td>290</td>
              <td>1606250</td>
              <td>4497500</td>
              <td>2570</td>
              <td>146567685</td>
              <td>384262640</td>
              <td>260325</td>
            </tr>
            <tr>
              <td>291</td>
              <td>1609375</td>
              <td>4506250</td>
              <td>2575</td>
              <td>148177060</td>
              <td>388768890</td>
              <td>262900</td>
            </tr>
            <tr>
              <td>292</td>
              <td>1612500</td>
              <td>4515000</td>
              <td>2580</td>
              <td>149789560</td>
              <td>393283890</td>
              <td>265480</td>
            </tr>
            <tr>
              <td>293</td>
              <td>1615625</td>
              <td>4523750</td>
              <td>2585</td>
              <td>151405185</td>
              <td>397807640</td>
              <td>268065</td>
            </tr>
            <tr>
              <td>294</td>
              <td>1618750</td>
              <td>4532500</td>
              <td>2590</td>
              <td>153023935</td>
              <td>402340140</td>
              <td>270655</td>
            </tr>
            <tr>
              <td>295</td>
              <td>1621875</td>
              <td>4541250</td>
              <td>2595</td>
              <td>154645810</td>
              <td>406881390</td>
              <td>273250</td>
            </tr>
            <tr>
              <td>296</td>
              <td>1625000</td>
              <td>4550000</td>
              <td>2600</td>
              <td>156270810</td>
              <td>411431390</td>
              <td>275850</td>
            </tr>
            <tr>
              <td>297</td>
              <td>1628125</td>
              <td>4558750</td>
              <td>2605</td>
              <td>157898935</td>
              <td>415990140</td>
              <td>278455</td>
            </tr>
            <tr>
              <td>298</td>
              <td>1631250</td>
              <td>4567500</td>
              <td>2610</td>
              <td>159530185</td>
              <td>420557640</td>
              <td>281065</td>
            </tr>
            <tr>
              <td>299</td>
              <td>1634375</td>
              <td>4576250</td>
              <td>2615</td>
              <td>161164560</td>
              <td>425133890</td>
              <td>283680</td>
            </tr>
            <tr>
              <td>300</td>
              <td>1637500</td>
              <td>4585000</td>
              <td>2620</td>
              <td>162802060</td>
              <td>429718890</td>
              <td>286300</td>
            </tr>
            <tr>
              <td>301</td>
              <td>1640625</td>
              <td>4593750</td>
              <td>2625</td>
              <td>164442685</td>
              <td>434312640</td>
              <td>288930</td>
            </tr>
            <tr>
              <td>302</td>
              <td>1643750</td>
              <td>4602500</td>
              <td>2630</td>
              <td>166086435</td>
              <td>438915140</td>
              <td>291560</td>
            </tr>
            <tr>
              <td>303</td>
              <td>1646875</td>
              <td>4611250</td>
              <td>2635</td>
              <td>167733310</td>
              <td>443526390</td>
              <td>294195</td>
            </tr>
            <tr>
              <td>304</td>
              <td>1650000</td>
              <td>4620000</td>
              <td>2640</td>
              <td>169383310</td>
              <td>448146390</td>
              <td>296835</td>
            </tr>
            <tr>
              <td>305</td>
              <td>1653125</td>
              <td>4628750</td>
              <td>2645</td>
              <td>171036435</td>
              <td>452775140</td>
              <td>299480</td>
            </tr>
            <tr>
              <td>306</td>
              <td>1656250</td>
              <td>4637500</td>
              <td>2650</td>
              <td>172692685</td>
              <td>457412640</td>
              <td>302130</td>
            </tr>
            <tr>
              <td>307</td>
              <td>1659375</td>
              <td>4646250</td>
              <td>2655</td>
              <td>174352060</td>
              <td>462058890</td>
              <td>304785</td>
            </tr>
            <tr>
              <td>308</td>
              <td>1662500</td>
              <td>4655000</td>
              <td>2660</td>
              <td>176014560</td>
              <td>466713890</td>
              <td>307445</td>
            </tr>
            <tr>
              <td>309</td>
              <td>1665625</td>
              <td>4663750</td>
              <td>2665</td>
              <td>177680185</td>
              <td>471377640</td>
              <td>310110</td>
            </tr>
            <tr>
              <td>310</td>
              <td>1668750</td>
              <td>4672500</td>
              <td>2670</td>
              <td>179348935</td>
              <td>476050140</td>
              <td>312780</td>
            </tr>
            <tr>
              <td>311</td>
              <td>1671875</td>
              <td>4681250</td>
              <td>2675</td>
              <td>181020810</td>
              <td>480731390</td>
              <td>315455</td>
            </tr>
            <tr>
              <td>312</td>
              <td>1675000</td>
              <td>4690000</td>
              <td>2680</td>
              <td>182695810</td>
              <td>485421390</td>
              <td>318135</td>
            </tr>
            <tr>
              <td>313</td>
              <td>1678125</td>
              <td>4698750</td>
              <td>2685</td>
              <td>184373935</td>
              <td>490120140</td>
              <td>320820</td>
            </tr>
            <tr>
              <td>314</td>
              <td>1681250</td>
              <td>4707500</td>
              <td>2690</td>
              <td>186055185</td>
              <td>494827640</td>
              <td>323510</td>
            </tr>
            <tr>
              <td>315</td>
              <td>1684375</td>
              <td>4716250</td>
              <td>2695</td>
              <td>187739560</td>
              <td>499543890</td>
              <td>326205</td>
            </tr>
            <tr>
              <td>316</td>
              <td>1687500</td>
              <td>4725000</td>
              <td>2700</td>
              <td>189427060</td>
              <td>504268890</td>
              <td>328905</td>
            </tr>
            <tr>
              <td>317</td>
              <td>1690625</td>
              <td>4733750</td>
              <td>2705</td>
              <td>191117685</td>
              <td>509002640</td>
              <td>331610</td>
            </tr>
            <tr>
              <td>318</td>
              <td>1693750</td>
              <td>4742500</td>
              <td>2710</td>
              <td>192811435</td>
              <td>513745140</td>
              <td>334320</td>
            </tr>
            <tr>
              <td>319</td>
              <td>1696875</td>
              <td>4751250</td>
              <td>2715</td>
              <td>194508310</td>
              <td>518496390</td>
              <td>337035</td>
            </tr>
            <tr>
              <td>320</td>
              <td>1700000</td>
              <td>4760000</td>
              <td>2720</td>
              <td>196208310</td>
              <td>523256390</td>
              <td>339755</td>
            </tr>
            <tr>
              <td>321</td>
              <td>1703125</td>
              <td>4768750</td>
              <td>2725</td>
              <td>197911435</td>
              <td>528025140</td>
              <td>342480</td>
            </tr>
            <tr>
              <td>322</td>
              <td>1706250</td>
              <td>4777500</td>
              <td>2730</td>
              <td>199617685</td>
              <td>532802640</td>
              <td>345210</td>
            </tr>
            <tr>
              <td>323</td>
              <td>1709375</td>
              <td>4786250</td>
              <td>2735</td>
              <td>201327060</td>
              <td>537588890</td>
              <td>347945</td>
            </tr>
            <tr>
              <td>324</td>
              <td>1712500</td>
              <td>4795000</td>
              <td>2740</td>
              <td>203039560</td>
              <td>542383890</td>
              <td>350685</td>
            </tr>
            <tr>
              <td>325</td>
              <td>1715625</td>
              <td>4803750</td>
              <td>2745</td>
              <td>204755185</td>
              <td>547187640</td>
              <td>353430</td>
            </tr>
            <tr>
              <td>326</td>
              <td>1718750</td>
              <td>4812500</td>
              <td>2750</td>
              <td>206473935</td>
              <td>552000140</td>
              <td>356180</td>
            </tr>
            <tr>
              <td>327</td>
              <td>1721875</td>
              <td>4821250</td>
              <td>2755</td>
              <td>208195810</td>
              <td>556821390</td>
              <td>358935</td>
            </tr>
            <tr>
              <td>328</td>
              <td>1725000</td>
              <td>4830000</td>
              <td>2760</td>
              <td>209920810</td>
              <td>561651390</td>
              <td>361695</td>
            </tr>
            <tr>
              <td>329</td>
              <td>1728125</td>
              <td>4838750</td>
              <td>2765</td>
              <td>211648935</td>
              <td>566490140</td>
              <td>364460</td>
            </tr>
            <tr>
              <td>330</td>
              <td>1731250</td>
              <td>4847500</td>
              <td>2770</td>
              <td>213380185</td>
              <td>571337640</td>
              <td>367230</td>
            </tr>
            <tr>
              <td>331</td>
              <td>1734375</td>
              <td>4856250</td>
              <td>2775</td>
              <td>215114560</td>
              <td>576193890</td>
              <td>370005</td>
            </tr>
            <tr>
              <td>332</td>
              <td>1737500</td>
              <td>4865000</td>
              <td>2780</td>
              <td>216852060</td>
              <td>581058890</td>
              <td>372785</td>
            </tr>
            <tr>
              <td>333</td>
              <td>1740625</td>
              <td>4873750</td>
              <td>2785</td>
              <td>218592685</td>
              <td>585932640</td>
              <td>375570</td>
            </tr>
            <tr>
              <td>334</td>
              <td>1743750</td>
              <td>4882500</td>
              <td>2790</td>
              <td>220336435</td>
              <td>590815140</td>
              <td>378360</td>
            </tr>
            <tr>
              <td>335</td>
              <td>1746875</td>
              <td>4891250</td>
              <td>2795</td>
              <td>222083310</td>
              <td>595706390</td>
              <td>381155</td>
            </tr>
            <tr>
              <td>336</td>
              <td>1750000</td>
              <td>4900000</td>
              <td>2800</td>
              <td>223833310</td>
              <td>600606390</td>
              <td>383955</td>
            </tr>
            <tr>
              <td>337</td>
              <td>1753125</td>
              <td>4908750</td>
              <td>2805</td>
              <td>225586435</td>
              <td>605515140</td>
              <td>386760</td>
            </tr>
            <tr>
              <td>338</td>
              <td>1756250</td>
              <td>4917500</td>
              <td>2810</td>
              <td>227342685</td>
              <td>610432640</td>
              <td>389570</td>
            </tr>
            <tr>
              <td>339</td>
              <td>1759375</td>
              <td>4926250</td>
              <td>2815</td>
              <td>229102060</td>
              <td>615358890</td>
              <td>392385</td>
            </tr>
            <tr>
              <td>340</td>
              <td>1762500</td>
              <td>4935000</td>
              <td>2820</td>
              <td>230864560</td>
              <td>620293890</td>
              <td>395205</td>
            </tr>
            <tr>
              <td>341</td>
              <td>1765625</td>
              <td>4943750</td>
              <td>2825</td>
              <td>232630185</td>
              <td>625237640</td>
              <td>398030</td>
            </tr>
            <tr>
              <td>342</td>
              <td>1768750</td>
              <td>4952500</td>
              <td>2830</td>
              <td>234398935</td>
              <td>630190140</td>
              <td>400860</td>
            </tr>
            <tr>
              <td>343</td>
              <td>1771875</td>
              <td>4961250</td>
              <td>2835</td>
              <td>236170810</td>
              <td>635151390</td>
              <td>403695</td>
            </tr>
            <tr>
              <td>344</td>
              <td>1775000</td>
              <td>4970000</td>
              <td>2840</td>
              <td>237945810</td>
              <td>640121390</td>
              <td>406535</td>
            </tr>
            <tr>
              <td>345</td>
              <td>1778125</td>
              <td>4978750</td>
              <td>2845</td>
              <td>239723935</td>
              <td>645100140</td>
              <td>409380</td>
            </tr>
            <tr>
              <td>346</td>
              <td>1781250</td>
              <td>4987500</td>
              <td>2850</td>
              <td>241505185</td>
              <td>650087640</td>
              <td>412230</td>
            </tr>
            <tr>
              <td>347</td>
              <td>1784375</td>
              <td>4996250</td>
              <td>2855</td>
              <td>243289560</td>
              <td>655083890</td>
              <td>415085</td>
            </tr>
            <tr>
              <td>348</td>
              <td>1787500</td>
              <td>5005000</td>
              <td>2860</td>
              <td>245077060</td>
              <td>660088890</td>
              <td>417945</td>
            </tr>
            <tr>
              <td>349</td>
              <td>1790625</td>
              <td>5013750</td>
              <td>2865</td>
              <td>246867685</td>
              <td>665102640</td>
              <td>420810</td>
            </tr>
            <tr>
              <td>350</td>
              <td>1793750</td>
              <td>5022500</td>
              <td>2870</td>
              <td>248661435</td>
              <td>670125140</td>
              <td>423680</td>
            </tr>
            <tr>
              <td>351</td>
              <td>1796875</td>
              <td>5031250</td>
              <td>2875</td>
              <td>250458310</td>
              <td>675156390</td>
              <td>426555</td>
            </tr>
            <tr>
              <td>352</td>
              <td>1800000</td>
              <td>5040000</td>
              <td>2880</td>
              <td>252258310</td>
              <td>680196390</td>
              <td>429435</td>
            </tr>
            <tr>
              <td>353</td>
              <td>1803125</td>
              <td>5048750</td>
              <td>2885</td>
              <td>254061435</td>
              <td>685245140</td>
              <td>432320</td>
            </tr>
            <tr>
              <td>354</td>
              <td>1806250</td>
              <td>5057500</td>
              <td>2890</td>
              <td>255867685</td>
              <td>690302640</td>
              <td>435210</td>
            </tr>
            <tr>
              <td>355</td>
              <td>1809375</td>
              <td>5066250</td>
              <td>2895</td>
              <td>257677060</td>
              <td>695368890</td>
              <td>438105</td>
            </tr>
            <tr>
              <td>356</td>
              <td>1812500</td>
              <td>5075000</td>
              <td>2900</td>
              <td>259489560</td>
              <td>700443890</td>
              <td>441005</td>
            </tr>
            <tr>
              <td>357</td>
              <td>1815625</td>
              <td>5083750</td>
              <td>2905</td>
              <td>261305185</td>
              <td>705527640</td>
              <td>443910</td>
            </tr>
            <tr>
              <td>358</td>
              <td>1818750</td>
              <td>5092500</td>
              <td>2910</td>
              <td>263123935</td>
              <td>710620140</td>
              <td>446820</td>
            </tr>
            <tr>
              <td>359</td>
              <td>1821875</td>
              <td>5101250</td>
              <td>2915</td>
              <td>264945810</td>
              <td>715721390</td>
              <td>449735</td>
            </tr>
            <tr>
              <td>360</td>
              <td>1825000</td>
              <td>5110000</td>
              <td>2920</td>
              <td>266770810</td>
              <td>720831390</td>
              <td>452655</td>
            </tr>
            <tr>
              <td>361</td>
              <td>1828125</td>
              <td>5118750</td>
              <td>2925</td>
              <td>268598935</td>
              <td>725950140</td>
              <td>455580</td>
            </tr>
            <tr>
              <td>362</td>
              <td>1831250</td>
              <td>5127500</td>
              <td>2930</td>
              <td>270430185</td>
              <td>731077640</td>
              <td>458510</td>
            </tr>
            <tr>
              <td>363</td>
              <td>1834375</td>
              <td>5136250</td>
              <td>2935</td>
              <td>272264560</td>
              <td>736213890</td>
              <td>461445</td>
            </tr>
            <tr>
              <td>364</td>
              <td>1837500</td>
              <td>5145000</td>
              <td>2940</td>
              <td>274102060</td>
              <td>741358890</td>
              <td>464385</td>
            </tr>
            <tr>
              <td>365</td>
              <td>1840625</td>
              <td>5153750</td>
              <td>2945</td>
              <td>275942685</td>
              <td>746512640</td>
              <td>467330</td>
            </tr>
            <tr>
              <td>366</td>
              <td>1843750</td>
              <td>5162500</td>
              <td>2950</td>
              <td>277786435</td>
              <td>751675140</td>
              <td>470280</td>
            </tr>
            <tr>
              <td>367</td>
              <td>1846875</td>
              <td>5171250</td>
              <td>2955</td>
              <td>279633310</td>
              <td>756846390</td>
              <td>473235</td>
            </tr>
            <tr>
              <td>368</td>
              <td>1850000</td>
              <td>5180000</td>
              <td>2960</td>
              <td>281483310</td>
              <td>762026390</td>
              <td>476195</td>
            </tr>
            <tr>
              <td>369</td>
              <td>1853125</td>
              <td>5188750</td>
              <td>2965</td>
              <td>283336435</td>
              <td>767215140</td>
              <td>479160</td>
            </tr>
            <tr>
              <td>370</td>
              <td>1856250</td>
              <td>5197500</td>
              <td>2970</td>
              <td>285192685</td>
              <td>772412640</td>
              <td>482130</td>
            </tr>
            <tr>
              <td>371</td>
              <td>1859375</td>
              <td>5206250</td>
              <td>2975</td>
              <td>287052060</td>
              <td>777618890</td>
              <td>485105</td>
            </tr>
            <tr>
              <td>372</td>
              <td>1862500</td>
              <td>5215000</td>
              <td>2980</td>
              <td>288914560</td>
              <td>782833890</td>
              <td>488085</td>
            </tr>
            <tr>
              <td>373</td>
              <td>1865625</td>
              <td>5223750</td>
              <td>2985</td>
              <td>290780185</td>
              <td>788057640</td>
              <td>491070</td>
            </tr>
            <tr>
              <td>374</td>
              <td>1868750</td>
              <td>5232500</td>
              <td>2990</td>
              <td>292648935</td>
              <td>793290140</td>
              <td>494060</td>
            </tr>
            <tr>
              <td>375</td>
              <td>1871875</td>
              <td>5241250</td>
              <td>2995</td>
              <td>294520810</td>
              <td>798531390</td>
              <td>497055</td>
            </tr>
            <tr>
              <td>376</td>
              <td>1875000</td>
              <td>5250000</td>
              <td>3000</td>
              <td>296395810</td>
              <td>803781390</td>
              <td>500055</td>
            </tr>
            <tr>
              <td>377</td>
              <td>1878125</td>
              <td>5258750</td>
              <td>3005</td>
              <td>298273935</td>
              <td>809040140</td>
              <td>503060</td>
            </tr>
            <tr>
              <td>378</td>
              <td>1881250</td>
              <td>5267500</td>
              <td>3010</td>
              <td>300155185</td>
              <td>814307640</td>
              <td>506070</td>
            </tr>
            <tr>
              <td>379</td>
              <td>1884375</td>
              <td>5276250</td>
              <td>3015</td>
              <td>302039560</td>
              <td>819583890</td>
              <td>509085</td>
            </tr>
            <tr>
              <td>380</td>
              <td>1887500</td>
              <td>5285000</td>
              <td>3020</td>
              <td>303927060</td>
              <td>824868890</td>
              <td>512105</td>
            </tr>
            <tr>
              <td>381</td>
              <td>1890625</td>
              <td>5293750</td>
              <td>3025</td>
              <td>305817685</td>
              <td>830162640</td>
              <td>515130</td>
            </tr>
            <tr>
              <td>382</td>
              <td>1893750</td>
              <td>5302500</td>
              <td>3030</td>
              <td>307711435</td>
              <td>835465140</td>
              <td>518160</td>
            </tr>
            <tr>
              <td>383</td>
              <td>1896875</td>
              <td>5311250</td>
              <td>3035</td>
              <td>309608310</td>
              <td>840776390</td>
              <td>521195</td>
            </tr>
            <tr>
              <td>384</td>
              <td>1900000</td>
              <td>5320000</td>
              <td>3040</td>
              <td>311508310</td>
              <td>846096390</td>
              <td>524235</td>
            </tr>
            <tr>
              <td>385</td>
              <td>1903125</td>
              <td>5328750</td>
              <td>3045</td>
              <td>313411435</td>
              <td>851425140</td>
              <td>527280</td>
            </tr>
            <tr>
              <td>386</td>
              <td>1906250</td>
              <td>5337500</td>
              <td>3050</td>
              <td>315317685</td>
              <td>856762640</td>
              <td>530330</td>
            </tr>
            <tr>
              <td>387</td>
              <td>1909375</td>
              <td>5346250</td>
              <td>3055</td>
              <td>317227060</td>
              <td>862108890</td>
              <td>533385</td>
            </tr>
            <tr>
              <td>388</td>
              <td>1912500</td>
              <td>5355000</td>
              <td>3060</td>
              <td>319139560</td>
              <td>867463890</td>
              <td>536445</td>
            </tr>
            <tr>
              <td>389</td>
              <td>1915625</td>
              <td>5363750</td>
              <td>3065</td>
              <td>321055185</td>
              <td>872827640</td>
              <td>539510</td>
            </tr>
            <tr>
              <td>390</td>
              <td>1918750</td>
              <td>5372500</td>
              <td>3070</td>
              <td>322973935</td>
              <td>878200140</td>
              <td>542580</td>
            </tr>
            <tr>
              <td>391</td>
              <td>1921875</td>
              <td>5381250</td>
              <td>3075</td>
              <td>324895810</td>
              <td>883581390</td>
              <td>545655</td>
            </tr>
            <tr>
              <td>392</td>
              <td>1925000</td>
              <td>5390000</td>
              <td>3080</td>
              <td>326820810</td>
              <td>888971390</td>
              <td>548735</td>
            </tr>
            <tr>
              <td>393</td>
              <td>1928125</td>
              <td>5398750</td>
              <td>3085</td>
              <td>328748935</td>
              <td>894370140</td>
              <td>551820</td>
            </tr>
            <tr>
              <td>394</td>
              <td>1931250</td>
              <td>5407500</td>
              <td>3090</td>
              <td>330680185</td>
              <td>899777640</td>
              <td>554910</td>
            </tr>
            <tr>
              <td>395</td>
              <td>1934375</td>
              <td>5416250</td>
              <td>3095</td>
              <td>332614560</td>
              <td>905193890</td>
              <td>558005</td>
            </tr>
            <tr>
              <td>396</td>
              <td>1937500</td>
              <td>5425000</td>
              <td>3100</td>
              <td>334552060</td>
              <td>910618890</td>
              <td>561105</td>
            </tr>
            <tr>
              <td>397</td>
              <td>1940625</td>
              <td>5433750</td>
              <td>3105</td>
              <td>336492685</td>
              <td>916052640</td>
              <td>564210</td>
            </tr>
            <tr>
              <td>398</td>
              <td>1943750</td>
              <td>5442500</td>
              <td>3110</td>
              <td>338436435</td>
              <td>921495140</td>
              <td>567320</td>
            </tr>
            <tr>
              <td>399</td>
              <td>1946875</td>
              <td>5451250</td>
              <td>3115</td>
              <td>340383310</td>
              <td>926946390</td>
              <td>570435</td>
            </tr>
            <tr>
              <td>400</td>
              <td>1950000</td>
              <td>5460000</td>
              <td>3120</td>
              <td>342333310</td>
              <td>932406390</td>
              <td>573555</td>
            </tr>
            <tr>
              <td>401</td>
              <td>1953125</td>
              <td>5468750</td>
              <td>3125</td>
              <td>344286435</td>
              <td>937875140</td>
              <td>576680</td>
            </tr>
            <tr>
              <td>402</td>
              <td>1956250</td>
              <td>5477500</td>
              <td>3130</td>
              <td>346242685</td>
              <td>943352640</td>
              <td>579810</td>
            </tr>
            <tr>
              <td>403</td>
              <td>1959375</td>
              <td>5486250</td>
              <td>3135</td>
              <td>348202060</td>
              <td>948838890</td>
              <td>582945</td>
            </tr>
            <tr>
              <td>404</td>
              <td>1962500</td>
              <td>5495000</td>
              <td>3140</td>
              <td>350164560</td>
              <td>954333890</td>
              <td>586085</td>
            </tr>
            <tr>
              <td>405</td>
              <td>1965625</td>
              <td>5503750</td>
              <td>3145</td>
              <td>352130185</td>
              <td>959837640</td>
              <td>589230</td>
            </tr>
            <tr>
              <td>406</td>
              <td>1968750</td>
              <td>5512500</td>
              <td>3150</td>
              <td>354098935</td>
              <td>965350140</td>
              <td>592380</td>
            </tr>
            <tr>
              <td>407</td>
              <td>1971875</td>
              <td>5521250</td>
              <td>3155</td>
              <td>356070810</td>
              <td>970871390</td>
              <td>595535</td>
            </tr>
            <tr>
              <td>408</td>
              <td>1975000</td>
              <td>5530000</td>
              <td>3160</td>
              <td>358045810</td>
              <td>976401390</td>
              <td>598695</td>
            </tr>
            <tr>
              <td>409</td>
              <td>1978125</td>
              <td>5538750</td>
              <td>3165</td>
              <td>360023935</td>
              <td>981940140</td>
              <td>601860</td>
            </tr>
            <tr>
              <td>410</td>
              <td>1981250</td>
              <td>5547500</td>
              <td>3170</td>
              <td>362005185</td>
              <td>987487640</td>
              <td>605030</td>
            </tr>
            <tr>
              <td>411</td>
              <td>1984375</td>
              <td>5556250</td>
              <td>3175</td>
              <td>363989560</td>
              <td>993043890</td>
              <td>608205</td>
            </tr>
            <tr>
              <td>412</td>
              <td>1987500</td>
              <td>5565000</td>
              <td>3180</td>
              <td>365977060</td>
              <td>998608890</td>
              <td>611385</td>
            </tr>
            <tr>
              <td>413</td>
              <td>1990625</td>
              <td>5573750</td>
              <td>3185</td>
              <td>367967685</td>
              <td>1004182640</td>
              <td>614570</td>
            </tr>
            <tr>
              <td>414</td>
              <td>1993750</td>
              <td>5582500</td>
              <td>3190</td>
              <td>369961435</td>
              <td>1009765140</td>
              <td>617760</td>
            </tr>
            <tr>
              <td>415</td>
              <td>1996875</td>
              <td>5591250</td>
              <td>3195</td>
              <td>371958310</td>
              <td>1015356390</td>
              <td>620955</td>
            </tr>
            <tr>
              <td>416</td>
              <td>2000000</td>
              <td>5600000</td>
              <td>3200</td>
              <td>373958310</td>
              <td>1020956390</td>
              <td>624155</td>
            </tr>
            <tr>
              <td>417</td>
              <td>2003125</td>
              <td>5608750</td>
              <td>3205</td>
              <td>375961435</td>
              <td>1026565140</td>
              <td>627360</td>
            </tr>
            <tr>
              <td>418</td>
              <td>2006250</td>
              <td>5617500</td>
              <td>3210</td>
              <td>377967685</td>
              <td>1032182640</td>
              <td>630570</td>
            </tr>
            <tr>
              <td>419</td>
              <td>2009375</td>
              <td>5626250</td>
              <td>3215</td>
              <td>379977060</td>
              <td>1037808890</td>
              <td>633785</td>
            </tr>
            <tr>
              <td>420</td>
              <td>2012500</td>
              <td>5635000</td>
              <td>3220</td>
              <td>381989560</td>
              <td>1043443890</td>
              <td>637005</td>
            </tr>
            <tr>
              <td>421</td>
              <td>2015625</td>
              <td>5643750</td>
              <td>3225</td>
              <td>384005185</td>
              <td>1049087640</td>
              <td>640230</td>
            </tr>
            <tr>
              <td>422</td>
              <td>2018750</td>
              <td>5652500</td>
              <td>3230</td>
              <td>386023935</td>
              <td>1054740140</td>
              <td>643460</td>
            </tr>
            <tr>
              <td>423</td>
              <td>2021875</td>
              <td>5661250</td>
              <td>3235</td>
              <td>388045810</td>
              <td>1060401390</td>
              <td>646695</td>
            </tr>
            <tr>
              <td>424</td>
              <td>2025000</td>
              <td>5670000</td>
              <td>3240</td>
              <td>390070810</td>
              <td>1066071390</td>
              <td>649935</td>
            </tr>
            <tr>
              <td>425</td>
              <td>2028125</td>
              <td>5678750</td>
              <td>3245</td>
              <td>392098935</td>
              <td>1071750140</td>
              <td>653180</td>
            </tr>
            <tr>
              <td>426</td>
              <td>2031250</td>
              <td>5687500</td>
              <td>3250</td>
              <td>394130185</td>
              <td>1077437640</td>
              <td>656430</td>
            </tr>
            <tr>
              <td>427</td>
              <td>2034375</td>
              <td>5696250</td>
              <td>3255</td>
              <td>396164560</td>
              <td>1083133890</td>
              <td>659685</td>
            </tr>
            <tr>
              <td>428</td>
              <td>2037500</td>
              <td>5705000</td>
              <td>3260</td>
              <td>398202060</td>
              <td>1088838890</td>
              <td>662945</td>
            </tr>
            <tr>
              <td>429</td>
              <td>2040625</td>
              <td>5713750</td>
              <td>3265</td>
              <td>400242685</td>
              <td>1094552640</td>
              <td>666210</td>
            </tr>
            <tr>
              <td>430</td>
              <td>2043750</td>
              <td>5722500</td>
              <td>3270</td>
              <td>402286435</td>
              <td>1100275140</td>
              <td>669480</td>
            </tr>
            <tr>
              <td>431</td>
              <td>2046875</td>
              <td>5731250</td>
              <td>3275</td>
              <td>404333310</td>
              <td>1106006390</td>
              <td>672755</td>
            </tr>
            <tr>
              <td>432</td>
              <td>2050000</td>
              <td>5740000</td>
              <td>3280</td>
              <td>406383310</td>
              <td>1111746390</td>
              <td>676035</td>
            </tr>
            <tr>
              <td>433</td>
              <td>2053125</td>
              <td>5748750</td>
              <td>3285</td>
              <td>408436435</td>
              <td>1117495140</td>
              <td>679320</td>
            </tr>
            <tr>
              <td>434</td>
              <td>2056250</td>
              <td>5757500</td>
              <td>3290</td>
              <td>410492685</td>
              <td>1123252640</td>
              <td>682610</td>
            </tr>
            <tr>
              <td>435</td>
              <td>2059375</td>
              <td>5766250</td>
              <td>3295</td>
              <td>412552060</td>
              <td>1129018890</td>
              <td>685905</td>
            </tr>
            <tr>
              <td>436</td>
              <td>2062500</td>
              <td>5775000</td>
              <td>3300</td>
              <td>414614560</td>
              <td>1134793890</td>
              <td>689205</td>
            </tr>
            <tr>
              <td>437</td>
              <td>2065625</td>
              <td>5783750</td>
              <td>3305</td>
              <td>416680185</td>
              <td>1140577640</td>
              <td>692510</td>
            </tr>
            <tr>
              <td>438</td>
              <td>2068750</td>
              <td>5792500</td>
              <td>3310</td>
              <td>418748935</td>
              <td>1146370140</td>
              <td>695820</td>
            </tr>
            <tr>
              <td>439</td>
              <td>2071875</td>
              <td>5801250</td>
              <td>3315</td>
              <td>420820810</td>
              <td>1152171390</td>
              <td>699135</td>
            </tr>
            <tr>
              <td>440</td>
              <td>2075000</td>
              <td>5810000</td>
              <td>3320</td>
              <td>422895810</td>
              <td>1157981390</td>
              <td>702455</td>
            </tr>
            <tr>
              <td>441</td>
              <td>2078125</td>
              <td>5818750</td>
              <td>3325</td>
              <td>424973935</td>
              <td>1163800140</td>
              <td>705780</td>
            </tr>
            <tr>
              <td>442</td>
              <td>2081250</td>
              <td>5827500</td>
              <td>3330</td>
              <td>427055185</td>
              <td>1169627640</td>
              <td>709110</td>
            </tr>
            <tr>
              <td>443</td>
              <td>2084375</td>
              <td>5836250</td>
              <td>3335</td>
              <td>429139560</td>
              <td>1175463890</td>
              <td>712445</td>
            </tr>
            <tr>
              <td>444</td>
              <td>2087500</td>
              <td>5845000</td>
              <td>3340</td>
              <td>431227060</td>
              <td>1181308890</td>
              <td>715785</td>
            </tr>
            <tr>
              <td>445</td>
              <td>2090625</td>
              <td>5853750</td>
              <td>3345</td>
              <td>433317685</td>
              <td>1187162640</td>
              <td>719130</td>
            </tr>
            <tr>
              <td>446</td>
              <td>2093750</td>
              <td>5862500</td>
              <td>3350</td>
              <td>435411435</td>
              <td>1193025140</td>
              <td>722480</td>
            </tr>
            <tr>
              <td>447</td>
              <td>2096875</td>
              <td>5871250</td>
              <td>3355</td>
              <td>437508310</td>
              <td>1198896390</td>
              <td>725835</td>
            </tr>
            <tr>
              <td>448</td>
              <td>2100000</td>
              <td>5880000</td>
              <td>3360</td>
              <td>439608310</td>
              <td>1204776390</td>
              <td>729195</td>
            </tr>
            <tr>
              <td>449</td>
              <td>2103125</td>
              <td>5888750</td>
              <td>3365</td>
              <td>441711435</td>
              <td>1210665140</td>
              <td>732560</td>
            </tr>
            <tr>
              <td>450</td>
              <td>2106250</td>
              <td>5897500</td>
              <td>3370</td>
              <td>443817685</td>
              <td>1216562640</td>
              <td>735930</td>
            </tr>
            <tr>
              <td>451</td>
              <td>2109375</td>
              <td>5906250</td>
              <td>3375</td>
              <td>445927060</td>
              <td>1222468890</td>
              <td>739305</td>
            </tr>
            <tr>
              <td>452</td>
              <td>2112500</td>
              <td>5915000</td>
              <td>3380</td>
              <td>448039560</td>
              <td>1228383890</td>
              <td>742685</td>
            </tr>
            <tr>
              <td>453</td>
              <td>2115625</td>
              <td>5923750</td>
              <td>3385</td>
              <td>450155185</td>
              <td>1234307640</td>
              <td>746070</td>
            </tr>
            <tr>
              <td>454</td>
              <td>2118750</td>
              <td>5932500</td>
              <td>3390</td>
              <td>452273935</td>
              <td>1240240140</td>
              <td>749460</td>
            </tr>
            <tr>
              <td>455</td>
              <td>2121875</td>
              <td>5941250</td>
              <td>3395</td>
              <td>454395810</td>
              <td>1246181390</td>
              <td>752855</td>
            </tr>
            <tr>
              <td>456</td>
              <td>2125000</td>
              <td>5950000</td>
              <td>3400</td>
              <td>456520810</td>
              <td>1252131390</td>
              <td>756255</td>
            </tr>
            <tr>
              <td>457</td>
              <td>2128125</td>
              <td>5958750</td>
              <td>3405</td>
              <td>458648935</td>
              <td>1258090140</td>
              <td>759660</td>
            </tr>
            <tr>
              <td>458</td>
              <td>2131250</td>
              <td>5967500</td>
              <td>3410</td>
              <td>460780185</td>
              <td>1264057640</td>
              <td>763070</td>
            </tr>
            <tr>
              <td>459</td>
              <td>2134375</td>
              <td>5976250</td>
              <td>3415</td>
              <td>462914560</td>
              <td>1270033890</td>
              <td>766485</td>
            </tr>
            <tr>
              <td>460</td>
              <td>2137500</td>
              <td>5985000</td>
              <td>3420</td>
              <td>465052060</td>
              <td>1276018890</td>
              <td>769905</td>
            </tr>
            <tr>
              <td>461</td>
              <td>2140625</td>
              <td>5993750</td>
              <td>3425</td>
              <td>467192685</td>
              <td>1282012640</td>
              <td>773330</td>
            </tr>
            <tr>
              <td>462</td>
              <td>2143750</td>
              <td>6002500</td>
              <td>3430</td>
              <td>469336435</td>
              <td>1288015140</td>
              <td>776760</td>
            </tr>
            <tr>
              <td>463</td>
              <td>2146875</td>
              <td>6011250</td>
              <td>3435</td>
              <td>471483310</td>
              <td>1294026390</td>
              <td>780195</td>
            </tr>
            <tr>
              <td>464</td>
              <td>2150000</td>
              <td>6020000</td>
              <td>3440</td>
              <td>473633310</td>
              <td>1300046390</td>
              <td>783635</td>
            </tr>
            <tr>
              <td>465</td>
              <td>2153125</td>
              <td>6028750</td>
              <td>3445</td>
              <td>475786435</td>
              <td>1306075140</td>
              <td>787080</td>
            </tr>
            <tr>
              <td>466</td>
              <td>2156250</td>
              <td>6037500</td>
              <td>3450</td>
              <td>477942685</td>
              <td>1312112640</td>
              <td>790530</td>
            </tr>
            <tr>
              <td>467</td>
              <td>2159375</td>
              <td>6046250</td>
              <td>3455</td>
              <td>480102060</td>
              <td>1318158890</td>
              <td>793985</td>
            </tr>
            <tr>
              <td>468</td>
              <td>2162500</td>
              <td>6055000</td>
              <td>3460</td>
              <td>482264560</td>
              <td>1324213890</td>
              <td>797445</td>
            </tr>
            <tr>
              <td>469</td>
              <td>2165625</td>
              <td>6063750</td>
              <td>3465</td>
              <td>484430185</td>
              <td>1330277640</td>
              <td>800910</td>
            </tr>
            <tr>
              <td>470</td>
              <td>2168750</td>
              <td>6072500</td>
              <td>3470</td>
              <td>486598935</td>
              <td>1336350140</td>
              <td>804380</td>
            </tr>
            <tr>
              <td>471</td>
              <td>2171875</td>
              <td>6081250</td>
              <td>3475</td>
              <td>488770810</td>
              <td>1342431390</td>
              <td>807855</td>
            </tr>
            <tr>
              <td>472</td>
              <td>2175000</td>
              <td>6090000</td>
              <td>3480</td>
              <td>490945810</td>
              <td>1348521390</td>
              <td>811335</td>
            </tr>
            <tr>
              <td>473</td>
              <td>2178125</td>
              <td>6098750</td>
              <td>3485</td>
              <td>493123935</td>
              <td>1354620140</td>
              <td>814820</td>
            </tr>
            <tr>
              <td>474</td>
              <td>2181250</td>
              <td>6107500</td>
              <td>3490</td>
              <td>495305185</td>
              <td>1360727640</td>
              <td>818310</td>
            </tr>
            <tr>
              <td>475</td>
              <td>2184375</td>
              <td>6116250</td>
              <td>3495</td>
              <td>497489560</td>
              <td>1366843890</td>
              <td>821805</td>
            </tr>
            <tr>
              <td>476</td>
              <td>2187500</td>
              <td>6125000</td>
              <td>3500</td>
              <td>499677060</td>
              <td>1372968890</td>
              <td>825305</td>
            </tr>
            <tr>
              <td>477</td>
              <td>2190625</td>
              <td>6133750</td>
              <td>3505</td>
              <td>501867685</td>
              <td>1379102640</td>
              <td>828810</td>
            </tr>
            <tr>
              <td>478</td>
              <td>2193750</td>
              <td>6142500</td>
              <td>3510</td>
              <td>504061435</td>
              <td>1385245140</td>
              <td>832320</td>
            </tr>
            <tr>
              <td>479</td>
              <td>2196875</td>
              <td>6151250</td>
              <td>3515</td>
              <td>506258310</td>
              <td>1391396390</td>
              <td>835835</td>
            </tr>
            <tr>
              <td>480</td>
              <td>2200000</td>
              <td>6160000</td>
              <td>3520</td>
              <td>508458310</td>
              <td>1397556390</td>
              <td>839355</td>
            </tr>
            <tr>
              <td>481</td>
              <td>2203125</td>
              <td>6168750</td>
              <td>3525</td>
              <td>510661435</td>
              <td>1403725140</td>
              <td>842880</td>
            </tr>
            <tr>
              <td>482</td>
              <td>2206250</td>
              <td>6177500</td>
              <td>3530</td>
              <td>512867685</td>
              <td>1409902640</td>
              <td>846410</td>
            </tr>
            <tr>
              <td>483</td>
              <td>2209375</td>
              <td>6186250</td>
              <td>3535</td>
              <td>515077060</td>
              <td>1416088890</td>
              <td>849945</td>
            </tr>
            <tr>
              <td>484</td>
              <td>2212500</td>
              <td>6195000</td>
              <td>3540</td>
              <td>517289560</td>
              <td>1422283890</td>
              <td>853485</td>
            </tr>
            <tr>
              <td>485</td>
              <td>2215625</td>
              <td>6203750</td>
              <td>3545</td>
              <td>519505185</td>
              <td>1428487640</td>
              <td>857030</td>
            </tr>
            <tr>
              <td>486</td>
              <td>2218750</td>
              <td>6212500</td>
              <td>3550</td>
              <td>521723935</td>
              <td>1434700140</td>
              <td>860580</td>
            </tr>
            <tr>
              <td>487</td>
              <td>2221875</td>
              <td>6221250</td>
              <td>3555</td>
              <td>523945810</td>
              <td>1440921390</td>
              <td>864135</td>
            </tr>
            <tr>
              <td>488</td>
              <td>2225000</td>
              <td>6230000</td>
              <td>3560</td>
              <td>526170810</td>
              <td>1447151390</td>
              <td>867695</td>
            </tr>
            <tr>
              <td>489</td>
              <td>2228125</td>
              <td>6238750</td>
              <td>3565</td>
              <td>528398935</td>
              <td>1453390140</td>
              <td>871260</td>
            </tr>
            <tr>
              <td>490</td>
              <td>2231250</td>
              <td>6247500</td>
              <td>3570</td>
              <td>530630185</td>
              <td>1459637640</td>
              <td>874830</td>
            </tr>
            <tr>
              <td>491</td>
              <td>2234375</td>
              <td>6256250</td>
              <td>3575</td>
              <td>532864560</td>
              <td>1465893890</td>
              <td>878405</td>
            </tr>
            <tr>
              <td>492</td>
              <td>2237500</td>
              <td>6265000</td>
              <td>3580</td>
              <td>535102060</td>
              <td>1472158890</td>
              <td>881985</td>
            </tr>
            <tr>
              <td>493</td>
              <td>2240625</td>
              <td>6273750</td>
              <td>3585</td>
              <td>537342685</td>
              <td>1478432640</td>
              <td>885570</td>
            </tr>
            <tr>
              <td>494</td>
              <td>2243750</td>
              <td>6282500</td>
              <td>3590</td>
              <td>539586435</td>
              <td>1484715140</td>
              <td>889160</td>
            </tr>
            <tr>
              <td>495</td>
              <td>2246875</td>
              <td>6291250</td>
              <td>3595</td>
              <td>541833310</td>
              <td>1491006390</td>
              <td>892755</td>
            </tr>
            <tr>
              <td>496</td>
              <td>2250000</td>
              <td>6300000</td>
              <td>3600</td>
              <td>544083310</td>
              <td>1497306390</td>
              <td>896355</td>
            </tr>
            <tr>
              <td>497</td>
              <td>2253125</td>
              <td>6308750</td>
              <td>3605</td>
              <td>546336435</td>
              <td>1503615140</td>
              <td>899960</td>
            </tr>
            <tr>
              <td>498</td>
              <td>2256250</td>
              <td>6317500</td>
              <td>3610</td>
              <td>548592685</td>
              <td>1509932640</td>
              <td>903570</td>
            </tr>
            <tr>
              <td>499</td>
              <td>2259375</td>
              <td>6326250</td>
              <td>3615</td>
              <td>550852060</td>
              <td>1516258890</td>
              <td>907185</td>
            </tr>
            <tr>
              <td>500</td>
              <td>2262500</td>
              <td>6335000</td>
              <td>3620</td>
              <td>553114560</td>
              <td>1522593890</td>
              <td>910805</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesLevelPage;

export const Head: React.FC = () => (
  <Seo
    title="Level requirements | Eversoul | Prydwen Institute"
    description="Material requirements for levels in Eversoul."
    game="eversoul"
  />
);
